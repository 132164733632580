<ng-progress id="myProgress"></ng-progress>
<div class="login-box">
    <div class="login-logo">
        <!-- <img class="mb-3" src="assets/img/app-logo.png" alt="" style="width: 200px;margin-top: 18px;margin-left: -10px"/> <b> | ADMIN </b> -->
        <img class="mb-3" src="assets/img/logo.png" alt="" style="width: 250px;margin-top: 11px;margin-left: -10px"/> <b> | ADMIN </b>
    </div>
    <!-- /.login-logo -->
    <div class="card">
        <div class="card-body login-card-body" *ngIf="forgetFormEnableStatus">
            <app-auth-alert></app-auth-alert>
            <p class="login-box-msg">Enter your E-Mail to receive verification code to reset your password..</p>
            <form [formGroup]="forgetForm" (ngSubmit)="onSubmitForgetForm()">
                <div class="form-group">
                    <div class="input-group mb-3">
                        <input type="text" formControlName="email" autocomplete="new-password" class="form-control" placeholder="Email" #email />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="forgetForm.get('email').touched && forgetForm.get('email').invalid"
                        class="text text-danger">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="submit" [disabled]="!forgetForm.valid" class="btn btn-primary btn-block">Forgot password</button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>

            <p class="mt-3 mb-1">
                <a href="javascript:void(0)" [routerLink]="['/auth/login']"
                    routerLinkActive="router-link-active">Login</a>
            </p>
        </div>
        <div class="card-body login-card-body" *ngIf="forgetResetFormEnableStatus">
            <app-auth-alert></app-auth-alert>
            <p class="login-box-msg">You are only one step away from your new password, recover your password now.</p>

            <form [formGroup]="forgetResetForm" (ngSubmit)="onSubmitForgetRestForm()">
                <!-- <div class="form-group">
                    <div class="input-group mb-3">
                        <input type="text" formControlName="email" class="form-control" placeholder="Email" #email />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                        <div *ngIf="s.email.invalid && (s.email.dirty || s.email.touched)" class="error text-danger">
                            <div *ngIf="s.email.errors.required">Email is required</div>
                            <div *ngIf="s.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div> -->
                <div class="form-group">
                    <div class="input-group mb-3">
                        <input type="text" formControlName="otp" autocomplete="new-password" class="form-control" placeholder="Enter OTP " #otp />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="s.otp.invalid && (s.otp.dirty || s.otp.touched)" class="text text-danger">
                        <div *ngIf="s.otp.errors.required">
                            OTP is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group mb-3">
                        <input class="input-bordered form-control" autocomplete="new-password" [type]="newPassFlag ? 'password' : 'text'"
                            id="new-pass" name="new-pass" formControlName="newPassword" [ngClass]="{
                        'not-valid': submitted && s.newPassword.errors
                      }" />
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa pass" [ngClass]="{
                            'fa-eye-slash': newPassFlag,
                            'fa-eye': !newPassFlag
                          }" (click)="newPass()"></i>
                            </span>
                        </div>
                    </div>
                    <!-- *ngIf="
                    ( s.newPassword.errors) ||
                    (s.newPassword.invalid &&
                      (s.newPassword.dirty || s.newPassword.touched))
                  "  -->
                    <div *ngIf="forgetResetForm.get('newPassword').touched && forgetResetForm.get('newPassword').invalid" class="text text-danger">
                        <div *ngIf="s.newPassword.errors.required">
                            Password is required.
                        </div>
                        <!-- <div *ngIf="s.newPassword.errors.minlength">Password must be at least 8 characters</div>
                        <div *ngIf="s.newPassword.errors.maxlength">Password must be least 16 characters</div> s.newPassword.errors.pattern-->
                        <div *ngIf="forgetResetForm.get('newPassword').getError('pattern')">
                            Your password must be between 10 and 16 characters in length and contain at least one combination of letters (both lowercase and uppercase), numbers, and symbols.
               
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group mb-3">
                        <input class="input-bordered form-control" autocomplete="new-password" [type]="confirmPassFlag ? 'password' : 'text'"
                            id="confirm-pass" name="confirm-pass" formControlName="confirmPassword" [ngClass]="{
                        'not-valid':
                          submitted && s.confirmPassword.errors
                      }" />
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa pass" [ngClass]="{
                            'fa-eye-slash': confirmPassFlag,
                            'fa-eye': !confirmPassFlag
                          }" (click)="confirmPass()"></i>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="
                    ( s.confirmPassword.errors) ||
                    (s.confirmPassword.invalid &&
                      (s.confirmPassword.dirty || s.confirmPassword.touched))
                  " class="text text-danger">
                        <!-- s.confirmPassword.errors.required -->
                        <div *ngIf="forgetResetForm.get('confirmPassword').getError('required')">
                            Confirm Password is required.
                        </div>
                        <div *ngIf=" s.confirmPassword.errors.mustMatch" class="ml-2 fs-16">
                            Confirm password must match.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="submit" [disabled]="!forgetResetForm.valid"
                            class="btn btn-primary btn-block">Reset password</button>
                    </div>
                </div>
            </form>

            <p class="mt-3 mb-1">
                <a href="javascript:void(0);" [routerLink]="['/auth/login']">Login</a>
            </p>
        </div>
        <!-- /.login-card-body -->
    </div>
</div>



<!-- <div class="login-page bg-primary-color">
    <div class="container">
        <div class="row">
            <app-auth-alert></app-auth-alert>
            <div class="d-flex w-100 align-center">
                <div class="col-sm-6">
                    <div class="text-left text-center-xs">
                        <img class="logo mb-3" src="assets/images/logo.png" alt="">
                        <p class="text-gray-1 font-weight-bold">Unchained Freedom and Equality Forever</p>
                    </div>
                </div>

                <div class="col-sm-6" *ngIf="forgetFormEnableStatus">
                    <div class="bg-light rounded-lg p-5">
                        <form [formGroup]="forgetForm" (ngSubmit)="onSubmitForgetForm()">
                            <p class="fs-24 font-weight-bold text-primary-dark text-center mb-5">Forget Password </p>

                            <input type="text" formControlName="email"
                                class="form-control border-dark border-bottom rounded-0 mb-5 pl-0 pt-3 pb-5"
                                placeholder="Email" #email />
                            <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)"
                                class="error text-danger">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            </div>

                            <input type="text" formControlName="securityQuestion"
                                class="form-control border-dark border-bottom rounded-0 mb-5 pl-0 pt-3 pb-5"
                                placeholder="security Answer " #securityQuestion />
                            <div *ngIf="f.securityQuestion.invalid && (f.securityQuestion.dirty || f.securityQuestion.touched)"
                                class="error text-danger">
                                <div *ngIf="f.securityQuestion.errors.required">
                                    Security Question is required.
                                </div>
                            </div>

                            <select class="form-control border-dark border-bottom rounded-0 mb-5 pl-0 pt-3 pb-5"
                                #securityQuestion formControlName="securityQuestion" name="question">
                                <option value="" selected disabled>Choose Security Question
                                </option>
                                 <option [value]="item" *ngFor="let item of seQuestionList">{{item}}</option> 
                            </select> 

                           <div *ngIf="f.securityQuestion.invalid && (f.securityQuestion.dirty || f.securityQuestion.touched)"
                                class="error text-danger">
                                <div *ngIf="f.securityQuestion.errors.required">
                                    Security Question is required.
                                </div>
                            </div>

                            <input type="text" formControlName="securityAnswer"
                                class="form-control border-dark border-bottom rounded-0 mb-5 pl-0 pt-3 pb-5"
                                placeholder="security Answer " #securityAnswer />
                            <div *ngIf="f.securityAnswer.invalid && (f.securityAnswer.dirty || f.securityAnswer.touched)"
                                class="error text-danger">
                                <div *ngIf="f.securityAnswer.errors.required">
                                    Security Answer is required
                                </div>
                            </div>

                            <div class="text-center mb-5">
                                Already have an account? <a href="javascript:void(0);" class="text-primary-dark"
                                    routerLink="/auth/login"> Sign In </a>
                            </div>

                            <button type="submit" class="btn col btn-primary-dark rounded"
                                [disabled]="forgetForm.pristine || forgetForm.invalid">
                                <a class="text-white">Continue</a>
                            </button>

                        </form>
                    </div>
                </div>


                <div class="col-sm-6" *ngIf="forgetResetFormEnableStatus">
                    <div class="bg-light rounded-lg p-5">
                        <form [formGroup]="forgetResetForm" (ngSubmit)="onSubmitForgetRestForm()">
                            <p class="fs-24 font-weight-bold text-primary-dark text-center mb-5">Forget Password Reset
                            </p>

                            <input type="text" formControlName="email"
                                class="form-control border-dark border-bottom rounded-0 mb-5 pl-0 pt-3 pb-5"
                                placeholder="Email" #email />
                            <div *ngIf="s.email.invalid && (s.email.dirty || f.email.touched)"
                                class="error text-danger">
                                <div *ngIf="s.email.errors.required">Email is required</div>
                                <div *ngIf="s.email.errors.email">Email must be a valid email address</div>
                            </div>

                            <input type="password" formControlName="newPassword"
                                class="form-control border-dark border-bottom rounded-0 mb-5 pl-0 pt-3 pb-5"
                                placeholder="Password" #newPassword />
                            <div *ngIf="s.newPassword.invalid && (s.newPassword.dirty || s.newPassword.touched)"
                                class="error text-danger">
                                <div *ngIf="s.newPassword.errors.required">Password is required</div>
                                <div *ngIf="s.newPassword.errors.minlength">Password must be at least 6 characters</div>
                            </div>

                            <input type="password" formControlName="confirmPassword"
                                class="form-control border-dark border-bottom rounded-0 mb-5 pl-0 pt-3 pb-5"
                                placeholder="Re-type Password" #confirmPassword />
                            <div *ngIf="s.confirmPassword.invalid && (s.confirmPassword.dirty || s.confirmPassword.touched)"
                                class="error text-danger">
                                <div *ngIf="s.confirmPassword.errors.required">Confirm Password is required</div>
                                <div *ngIf="s.confirmPassword.errors.mustMatch">Passwords must match</div>
                            </div>

                            <input type="text" formControlName="otp"
                                class="form-control border-dark border-bottom rounded-0 mb-5 pl-0 pt-3 pb-5"
                                placeholder="Enter OTP " #otp />
                            <div *ngIf="s.otp.invalid && (s.otp.dirty || s.otp.touched)" class="error text-danger">
                                <div *ngIf="s.otp.errors.required">
                                    OTP is required
                                </div>
                            </div>

                            <div class="text-center mb-5">
                                Already have an account? <a href="javascript:void(0);" class="text-primary-dark"
                                    routerLink="/auth/login"> Sign In </a>
                            </div>

                            <button type="submit" class="btn col btn-primary-dark rounded"
                                [disabled]="forgetResetForm.pristine || forgetResetForm.invalid">
                                <a class="text-white">Continue</a>
                            </button>

                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div> -->