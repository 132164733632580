import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

// import custom validator to validate that password and confirm password fields match
import { MustMatch } from './../../core/_helpers/must-match.validator';

// import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { AlertService, AuthenticationService } from './../../core/_services';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  forgetForm: FormGroup;
  forgetFormEnableStatus: boolean = true;
  loading = false;
  submitted = false;
  //data = [];
  forgetResetForm: FormGroup;
  forgetResetFormEnableStatus: boolean = false;

  seQuestionList: any = {};

  progressRef: NgProgressRef;

  newPassFlag: boolean = true;
  confirmPassFlag: boolean = true;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    // private _loadingBar: SlimLoadingBarService,
    private alertService: AlertService,
    private progress: NgProgress
  ) {

    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.createForgetPaswdForm();
    this.createForgetResetPaswdForm();

  }

  ngOnInit(): void {
    this.progressRef = this.progress.ref('myProgress');
  }

  createForgetPaswdForm() {
    this.forgetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      // securityQuestion: ['', Validators.required],
      // securityAnswer: ['', Validators.required]
    });
  }

  //Validators.minLength(8), Validators.maxLength(16),
  createForgetResetPaswdForm() {
    this.forgetResetForm = this.fb.group(
      {
        newPassword: ['', [
          Validators.required,
          Validators.pattern("(?=.*[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$!%?_@.#&+-]).\\S{9,15}$")
        ]],
        confirmPassword: ['', Validators.required],
        otp: ['', Validators.required]
      },
      {
        validator: MustMatch("newPassword", "confirmPassword"),
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgetForm.controls;
  }

  newPass() {
    this.newPassFlag = !this.newPassFlag;
  }

  confirmPass() {
    this.confirmPassFlag = !this.confirmPassFlag;
  }

  get s() {
    return this.forgetResetForm.controls;
  }

  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }

  onSubmitForgetForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      return;
    }

    // this._loadingBar.start();
    this.progressRef.start();
    this.loading = true;
    this.authenticationService.forgetPassword(this.forgetForm.value).pipe(first()).subscribe((res: any) => {
      if (res.status == 'success') {
        // this.alertService.success('OTP send Mail', true);
        this.alertService.success('OTP sent to Email')
        // this._loadingBar.complete();
        this.progressRef.complete();
        this.forgetFormEnableStatus = false;
        this.forgetResetFormEnableStatus = true;
        //this.router.navigate(['/login']);
      }
      else {
        this.alertService.error(res.message);
        this.progressRef.complete();
        
      }
    },
      error => {
        this.alertService.error(error);
        // this._loadingBar.stop();

        this.loading = false;
      });
  }


  onSubmitForgetRestForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgetResetForm.invalid) {
      return;
    }

    // this._loadingBar.start();
    this.progressRef.start();
    this.loading = true;
    // let obj = this.forgetResetForm.value;
    let newPasswordEncrypt = this.set('123456$#@$^@1ERF', this.forgetResetForm.get('newPassword').value);
    let confirmPasswordEncrypt = this.set('123456$#@$^@1ERF', this.forgetResetForm.get('confirmPassword').value);
    let obj = {
      newPassword: newPasswordEncrypt,
      confirmPassword: confirmPasswordEncrypt,
      otp: this.forgetResetForm.get('otp').value
    };
    let obj2 = {
      email: this.forgetForm.get('email').value
    }
    Object.assign(obj, obj2)
    //this.forgetResetForm.value
    this.authenticationService.forgetPasswordReset(obj).pipe(first()).subscribe((res: any) => {
      if (res.status == 'success') {
        // this.alertService.success('Password changed Successfully', true);
        this.alertService.success('Password changed Successfully')
        // this._loadingBar.complete();
       
        setTimeout(() => {
          this.progressRef.complete();
          this.router.navigate(['/login']);
        }, 1000); 
      }
      else {
        console.log("dfdfd", res.message)
        this.alertService.error(res.message);
        this.progressRef.complete();
      }
    },
      error => {
        this.alertService.error(error);
        // this._loadingBar.stop();
        this.progressRef.destroy();
        this.loading = false;
      });
  }






}
