import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    NavigationCancel,
    Event,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from '@angular/router';  //:loading-bar

import { HttpClient } from '@angular/common/http';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';

//declare var jQuery:any;
declare var $: any;
declare const document: Document;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

    progressRef: NgProgressRef;

    constructor(private http: HttpClient,
        private router: Router,
        private progress: NgProgress
    ) {
        this.router.events.subscribe((event: Event) => {
            this.navigationInterceptor(event);
        });
    }

    ngOnInit() {
        // this.progressRef = this.progress.ref('myProgress');
        // this.progressRef.start();
    }

    //:loading bar event occur:
    private navigationInterceptor(event: Event): void {
        if (event instanceof NavigationStart) {
            // this.progressRef.start();
        }
        if (event instanceof NavigationEnd) {
            // this.progressRef.complete();
        }
        if (event instanceof NavigationCancel) {
            // this.progressRef.destroy();
        }
        if (event instanceof NavigationError) {
            // this.progressRef.destroy();
        }
    }


}
