import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class UserService {

    ENVIRONMENT_URL = environment.apiUrl;
    headers: any;
    emails: any;

    private isSuperAdmin = new Subject<boolean>();

    constructor(private http: HttpClient, private router: Router) {
        this.isSuperAdmin.next(false);
    }

    getDashboardDetails_() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/dashboard`)
            .pipe(map((data: any) => {
                return data;
            }));

    }

    getrefData() {
        // return this.http.get(`${this.ENVIRONMENT_URL}/admin/getAllUserRefBonuscsv`)
        //     .pipe(map((data: any) => {
        //         return data;
        //     }));

          return this.http.get(`${this.ENVIRONMENT_URL}/admin/getAllUserRefBonus`)
            .pipe(map((data: any) => {
                return data;
            }));

    }

    

    getUserInfo(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/getoneMember`, data);

    }

    //TRANSACTION :
    getTransactionList() {
        //this.http.get(url).map(res => res.json());
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/transaction/buytransactionlist`).pipe(map((data: any) => {
            return data;
        }));
    }

    getDistributionTransactionList() {
        //this.http.get(url).map(res => res.json());
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/transaction/tokenDistributionTransactionList`).pipe(map((data: any) => {
            return data;
        }));
    }

    getRefTransactionList() {
        //this.http.get(url).map(res => res.json());
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/transaction/reftransactionlist`).pipe(map((data: any) => {
            return data;
        }));
    }

    getAdminTransactionList() {
        //this.http.get(url).map(res => res.json());
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/transaction/admintransferlist`).pipe(map((data: any) => {
            return data;
        }));
    }

    // member view:
    getOneTransactionList(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/getOneTxHistory`, data);
    }

    getOneRefTransactionList(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/getOneUserRefTX`, data);
    }

    getOneTransactionInfo(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/getOneTx`, data);
    }

    approveOneTransaction(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/approveOneTx`, data);
    }

  
    updateReferrals(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/UpdateReferralSettings`, data);
    }

    updateCoin(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/UpdateCoinPrice`, data);
    }

    resetPassword(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/resetUserPassword`, data);
    }
    resetPasswordEmail(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/resetUserPasswordEmail`, data);
    }

    updateUserStatus(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateAccountStatus`, data);
    }

    getKycList() {
        return this.http.get(
            `${this.ENVIRONMENT_URL}/admin/data/getKycStatus`,
            {}
        );
    }



    getreferrals() {
        return this.http.get(
            `${this.ENVIRONMENT_URL}/admin/getReferralSettings`,
            {}
        );
    }

   
    getOneKycStatus(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/getKycStatus`, data);

    }



    addAdminBankDetail(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/addBankDetail`, data);
    }

    getbankDetailsAdmin() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/bankDetailsAdmin`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getcountryCodes() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/countryCodes`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    changePassword(data) {
        //const headerParams = this.headerAuthorization();
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/changePaassword`, data);
    }

    updateKycStatus(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/approveKYC`, data);
    }

    updateAccountStatus(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateAccountStatus`, data);
    }

    FilterGetRequests() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/FilterGetRequests`)
            .pipe(map((filterData: any) => {
                return filterData;
            }));
    }

    getCryptoWallets() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/cryptoWallets`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getEscrowBalances() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/escrowBalances`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getLedger() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/ledger`)
            .pipe(map((data: any) => {
                return data;
            }));
    }
    //-----------------------------------------------------------------------------------

    headerAuthorization() {
        console.log('token H:' + localStorage.getItem('token'));
        return new HttpHeaders().set("Authorization", localStorage.getItem('token'));
        // return new HttpHeaders().set("Authorization", 'Bearer ' + localStorage.getItem('token'));
        //  console.log('check header'+JSON.stringify(headers_object));
    }

    //userBalance
    getBalance() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/filterWalletBalances`)
            .pipe(map((data: any) => {
                return data;
            }));
    }
    // member view:
    getOneWalletBalance(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/getWalletBalance`, data);

    }

    getViewOneBalance(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/filterWalletBalancesById`, data);
    }

    //Fee Schedule
    FeeSchedule() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/fee`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    // Update Fee 
    updateFee(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/updateFee`, data);
    }

    //Bank Accounts
    bankAccounts() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/getBankInfo`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getViewOneBankAcc(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/getBankInfoById`, data);
    }

    //Bitcoin Address
    bitcoinAddress() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/FilterAddressess`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    //user history
    userHistory() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/filterUserHistory`)
            .pipe(map((data: any) => {
                return data;
            }));
    }


    // history action
    HistoryActions() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/showActions`)
            .pipe(map((data: any) => {
                return data;
            }));
    }
    getDailyTransactions() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/dailyTransactions`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getMonthlyTransactions() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/monthlyTransactions`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getTopUpQRData() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/topUpQR`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    // TOPUP:
    getTopupList() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/getTopupList`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getOneTopUp(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/getOneTopUp`, data);
    }

    approveTopUp(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/approveTopUp`, data);
    }

    topUp(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/topUp`, data);
    }


    //WITHDRAW:
    getWithdrawList() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/getWithdrawList`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getOneWithdraw(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/getOneWithdraw`, data);
    }

    approveWithdraw(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/approveWithdraw`, data);
    }

    withdraw(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/withdraw`, data);
    }

    updateMarketDataKey(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateMarketDataKey`, data);
    }

    //REG MEMBER :
    getRegisteredMemberList() {
        //this.http.get(url).map(res => res.json());
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/data/getRegisterList`).pipe(map((data: any) => {
            return data;
        }));
    }

    //REG member view:
    getOneRegMemberList(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/getOneRegistered`, data);
    }

    //contractAddress
    contractAddress(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/contractAddress`, data);
    }

    // config settting.
    setStatusBuy(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/setStatus/buy`, data);
    }

    setStatusRef(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/setStatus/refStatus`, data);
    }

    setStatusSell(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/setStatus/sell`, data);
    }

    setStatusSend(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateTokenDistributionStatus`, data);
    }

    setStatusLogin(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/setStatus/login`, data);
    }

    setStatusRegister(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/setStatus/register`, data);
    }

    adminSettings() {

        return this.http.get(`${this.ENVIRONMENT_URL}/admin/settings`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    bulkUploadUser(file: File) {
        const formData: FormData = new FormData();
        formData.append('accounts', file);
        console.log(formData);
        formData.forEach((value, key) => {
            console.log(key + " " + value);
        });
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/uploadBonusTranferList`, formData);
    }

    
    coinSettings() {

        return this.http.get(`${this.ENVIRONMENT_URL}/admin/getCoinPrice`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    updateEmaillSetting(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateEmailSettings`, data);
    }


    updateAccountSetting(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateAccountSettings`, data);
    }

    updateInfuraSetting(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateInfuraSettings`, data);
    }

    updateFeeSettings(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/data/updateFee`, data);
    }

    tokenRewardsData(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/pzrprice`, data);
    }

    updateTrxAddress(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateWlthxAddressDetails`, data);
    }

    getTokenRewards() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/pzrprice`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    verifyBonusold() {
        
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/verifyBonusDistributionEmail`)
            .pipe(map((data: any) => {
                return data;
            })); 
    }

    verifyBonus(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/verifyBonusDistributionEmail`, data);
    }

    googleCaptcha(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateGoogleCaptchaKey`, data);
    }

    // adding admin
    addAdmin(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/addAdminUser`, data);
    }

    addPhase(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/addPresalePhase`, data);
    }

    addCommission(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/createCommissionLevel`, data);
    }

    editPhase(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/editPresalePhase`, data);
    }

    editAdmin(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/editAdmin`, data);
    }

    
    updatecommissionDetail(data){
        return this.http.put(`${this.ENVIRONMENT_URL}/admin/updateCommissionLevel/`+data._id, data);
    }

    // Get Admin Detail 
    getOneAdminDetail(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/getOneAdmin`, data);
    }

    
    getOnePhaseDetail(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/getPresalePhase`, data);   
    }

    // getOneCommissionDetail(data) {
    //     return this.http.get(`${this.ENVIRONMENT_URL}/admin/getCommissionLevel`, data);   
    // }

    getOneCommissionDetail(data) {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/getCommissionLevel/`+ data._id)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    
    getCurrencyList(){
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/getCurrencyLists`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    updatebuyCurrencyDetail(data){
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updatebuyCurrencyDetails`, data);
    }

    addsellCurrencyDetail(data){
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/addSellcurrency`, data);
    }

    updatesellCurrencyDetail(data){
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updatesellCurrencyDetails`, data);
    }

    updatewhitelistDetail(data){
        return this.http.put(`${this.ENVIRONMENT_URL}/admin/updateWhitelistEmail/`+data._id, data);
    }

    getsingleCurrencyDetail(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/getOnebuyCurrency`, data);

    }

    getsinglesellCurrencyDetail(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/getOnesellCurrency`, data);

    }

    getwhitelistDetail(data) {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/whitelistEmails/`+data._id);

    }

    getsellCurrencyList(){
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/getsellCurrencyLists`)
            .pipe(map((data: any) => {
                return data;
            }));
        }

        getWhiteList(){
            return this.http.get(`${this.ENVIRONMENT_URL}/admin/whitelistEmails`)
                .pipe(map((data: any) => {
                    return data;
                }));
            }
       

    //Admin List
    getAdminList() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/getAdminLists`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getPhaseList() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/getAllPresales`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getCommissionList() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/getAllCommissionLevels`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    
    approveAmount(data) {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/ApproveTokenMultipleSender`, data);
    }

    // delete admin
    deleteAdmin(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/deleteAdminUser`, data);
    }

    deleteWhietlist(data) {
        return this.http.delete(`${this.ENVIRONMENT_URL}/admin/deleteWhitelistEmail/`+data._id);
    }

    deleteCommissionLevel(data) {
        return this.http.delete(`${this.ENVIRONMENT_URL}/admin/deleteCommissionLevel/`+data._id);
    }

    

    // setSuperAdminStatus(status: boolean) {
    //     console.log(`entered into the setUser loggedin status`);
    //     this.isSuperAdmin.next(status);
    // }

    // getSuperAdminStatus(): Observable<boolean> {
    //     console.log(`entered into get super admin status`);
    //     return this.isSuperAdmin.asObservable();
    // }

    getSuperAdminDetail(data) {
        console.log(data);
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/getAdminuserDetail`, data);
    }

    //updateBalance
    updateBalance(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateUserBalance`, data);
    }

    updateWallet(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateExternalWallet`, data);
    }

    updateWalletSetting(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/updateAddress`, data);
    }

    getDashboardDetails() {
        return this.http.get(`${this.ENVIRONMENT_URL}/admin/dashboard`);
    }
}
