<ng-progress id="myProgress"></ng-progress>
<div class="login-box" *ngIf="loginbox">
    <div class="login-logo">
        <!-- <img class="mb-3" src="assets/img/app-logo.png" alt="" style="width: 200px;margin-top: 18px;margin-left: -10px"/> <b> | ADMIN </b> -->
        <img class="mb-3" src="assets/img/logo.png" alt="" style="width: 250px;margin-top: 11px;margin-left: -10px"/> <b> | ADMIN </b>
    </div>

    <!-- /.login-logo -->
    <div class="card">

        
        <div class="card-body login-card-body">
            <app-auth-alert></app-auth-alert>
            <p class="login-box-msg">Sign in to start your session</p>

            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">

                    <div class="input-group">
                        <input type="text" formControlName="email" class="form-control" #email 
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors || f.email.invalid && (f.email.dirty || f.email.touched) }"/>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.email.errors || f.email.invalid && (f.email.dirty || f.email.touched)" class="error invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <!-- [ngClass]="{ 'is-invalid': submitted && f.firstName.errors || f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)}"  -->
                        <input [type]="fieldTextType ?'text':'password'" formControlName="password"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors || f.password.invalid && (f.password.dirty || f.password.touched) }" />
                     
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa" [ngClass]="{
                                      'fa-eye-slash': !fieldTextType,
                                      'fa-eye': fieldTextType
                                    }" (click)="toggleFieldTextType()"></i>
                            </span>
                        </div>
                        <div *ngIf="submitted && f.password.errors || f.password.invalid && (f.password.dirty || f.password.touched)" class="error invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                   
                </div>

                 <!-- <div class="input-group">
                        <input type="password" formControlName="password" class="form-control" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        
                    </div>
                    <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="text text-danger">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div> -->

                <div class="btnMain">
                    
                        <button type="submit" class="btn btn-md btn-primary btn-block" style=" width:100px;padding-top: 4px;
                        margin-top: 10px;">Login</button>
                   
                </div>
            </form>
            <!-- /.social-auth-links -->
            <p class="mb-1">
                <a href="javascript:void(0);" [routerLink]="['/auth/forget-password']" >I forgot my password</a>
            </p>
            <p class="mb-0">
                <!-- <a routerLink="/auth/register" class="text-center">Create an account</a> -->

            </p>
        </div>
        <!-- /.login-card-body -->
    </div>

    
</div>

<div class="verify-box" *ngIf="verifybox">
    <!-- <div class="login-logo">
     
        <img class="mb-3" src="assets/img/logo.png" alt="" style="width: 200px;margin-top: 18px;margin-left: -10px"/> <b> | ADMIN </b>
    </div> -->
    

    <div class="card">

        
        <div class="card-body login-card-body" style="text-align: center;">

            <h3>Awaiting Confirmation</h3>

            <h4>Do not close this window until opening the email link</h4>

            <p>We just sent an email to <b>{{ email }}</b>. Click verify in that to proceed further</p>
            
            <!-- /.social-auth-links -->
            <p class="mb-1">
                <!-- <a href="javascript:void(0);" [routerLink]="['/auth/forget-password']" >I forgot my password</a> -->
            </p>
            <p class="mb-0">
                <!-- <a routerLink="/auth/register" class="text-center">Create an account</a> -->

            </p>
        </div>
        <!-- /.login-card-body -->
    </div>
</div>