import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AuthenticationService } from './../../core/_services';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

  userid: any;
  tkid: any;
  verfystatus: boolean = false;

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private alertService: AlertService,) { }

  ngOnInit() {
    console.log(this.router.url.split('/')[3])
    this.userid = this.router.url.split('/')[3];
    this.tkid = this.router.url.split('/')[4];
    this.verify();

}

verify(){
  this.authenticationService.verifyOTPnw({"email":this.userid,"otp":this.tkid}).subscribe((data: any) => {
    if (data.status === 'success') {
      this.verfystatus = true;
     // this.router.navigate(['/admin/home']);
    }else{
      // this.router.navigate(['/auth/login'])
    }
  },
    (err) => {
      
    });
}

}



