<div class="login-box">
    <div class="login-logo">
        <!-- <img class="mb-3" src="assets/img/app-logo.png" alt="" style="width: 200px;margin-top: 18px;margin-left: -10px" /> <b> | ADMIN </b> -->
        <img class="mb-3" src="assets/img/logo.png" alt="" style="width: 200px;margin-top: 18px;margin-left: -10px" /> <b> | ADMIN </b>
    </div>

    <!-- /.login-logo -->
    <div class="card">


        <div class="card-body login-card-body">
            <app-auth-alert></app-auth-alert>
            <p class="login-box-msg">Verify 2FA</p>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <form>
                            <p class="text-success">OTP Sent To Your Registered Mail ID</p>
                            <input class="form-control form-input-height mb-3" autocomplete="off" placeholder="Enter OTP" [(ngModel)]="otp"
                                name="otp" />
                            <p *ngIf="loginOTPErrorMessage" style="color: white;background-color: #f45662;"
                                class="alert-box">**Wrong OTP..!! Please try again..!!</p>
                            <div class="btnMain">
                               
                                    <button class="btn btn-md btn-primary btn-block" style="  width: 100px;padding-top: 4px;
                                    margin-top: 10px;" (click)="verifyOTP()" type="button">
                                        <a class="text-white">Submit</a>
                                    </button>
                                <!-- /.col -->
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <!-- /.social-auth-links -->
            <p class="mb-1">
                <!-- <a href="javascript:void(0);" [routerLink]="['/auth/forget-password']" >I forgot my password</a> -->
            </p>
            <p class="mb-0">
                <!-- <a routerLink="/auth/register" class="text-center">Create an account</a> -->

            </p>
        </div>
        <!-- /.login-card-body -->
    </div>
</div>