import { Component, EventEmitter, Inject, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//added:
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService } from './../../core/_services';
import { DOCUMENT } from '@angular/common';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { interval, timer } from 'rxjs';

//declare var jQuery:any;
declare var $: any;
declare const document: Document;
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    otpForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    fieldTextType: boolean;

    loginStatus: boolean = true;
    loginOTPStatus: boolean = false;

    loginbox: boolean = true;
    verifybox: boolean = false;
    email: any;
    authsession: any;
    id:any;

    progressRef: NgProgressRef;

    constructor(@Inject(DOCUMENT) private document: Document,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private alertService: AlertService,
        private progress: NgProgress
    ) {

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/admin/home']);
            // this.loginbox = false;
            // this.verifybox = true;
        }
    }

    ngOnInit() {

        // if(localStorage.getItem("authSession")){
        //     this.battleInit();
        //     this.id = setInterval(() => {
        //       this.battleInit(); 
        //     }, 1000);
        //   }else{
        //     if(localStorage.getItem("token")){
        //         this.router.navigate(['/admin/home']);
        //     }
        //   }
       
          
        this.id = setInterval(() => {
            this.battleInit(); 
          }, 1000);
        
       

        this.progressRef = this.progress.ref('myProgress');

        this.document.body.className = "hold-transition login-page";

        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.otpForm = this.formBuilder.group({
            otp: ['', Validators.required]
        });

        this.email = localStorage.getItem("email");
        this.authsession = localStorage.getItem("authSession");

        
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    }

    battleInit(){
        
        let lemail = localStorage.getItem('email');
        let lauth = localStorage.getItem("authSession")

        if(lemail && lauth){
            this.authenticationService.isverifyOTPnw({"email":localStorage.getItem("email"),"otp":localStorage.getItem("authSession")}).subscribe((data: any) => {
                if (data.status === 'success') {
                 this.router.navigate(['/admin/home']);
                }else if (data.status === 'fail' && data.message === 'otp expired'){
                    this.router.navigate(['/auth/login']);
                }
              },
                (err) => {
                  
                });
        }

       
        
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    get s() {
        return this.otpForm.controls;
    }

    set(keys, value) {
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encrypted.toString();
    }


    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.progressRef.start();

        this.loading = true;
        var encrypted = this.set('123456$#@$^@1ERF', this.f.password.value);
        this.email = this.f.email.value;
        localStorage.setItem("email", this.email)
        this.authenticationService.loginnw(this.f.email.value, encrypted)
            .pipe(first())
            .subscribe(
                (res: any) => {
                    //\   console.log(data)
                    if (res.status === 'success') {
                        //  console.log(this.alertService);
                       // this.alertService.success("Login successfully", true);
                        this.progressRef.complete();
                        // setTimeout(() => {
                        //     this.router.navigate([this.returnUrl]);
                        // }, 1500); 
                        
                        this.loginbox = false;
                        this.verifybox = true;
                      // this.router.navigate(['/auth/verify2fa']);
                    }
                    else {
                        this.alertService.error(res.message);
                        this.progressRef.complete();
                    }
                },
                error => {
                    //  console.log('Login failed:Error');
                    this.alertService.error(error);
                    // this.progressRef.destroy(); // stop 
                    this.loading = false;
                });

    }


    onSubmitOTP() {
        // console.log('otp val:'+this.otpForm.value);
        // stop here if form is invalid
        if (this.otpForm.invalid) {
            return;
        }

        // this._loadingBar.start();
        this.progressRef.start();
        this.loading = true;

        const objForm = {
            email: this.f.email.value,
            otp: this.s.otp
        };

        this.authenticationService.verifyOTP(objForm)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.success("OTP hase been sent successfully to your registered mail ID", true);
                    this.progressRef.complete();
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    //  console.log('Login failed:Error');
                    this.alertService.error(error);
                    // this.progressRef.destroy();
                    this.loading = false;
                });



        // this.authenticationService.sendOTP(this.s.otp.value)
        // .subscribe(
        //     data => {
        //         console.log('eeeeeeeee'+data);
        //      //this.alertService.success("Login successfully", true);
        //      //this._loadingBar.complete();
        //     // this.router.navigate([this.returnUrl]);    
        //     });


    }

    ngOnDestroy() {
        if (this.id) {
          clearInterval(this.id);
        }
      }

    onSubmitResendOTP() { }
}
