  <!-- Apply the background styles to the body element -->
<body style="background-color: #2c2c2e; color: white; margin: 0; padding: 0;">

   

    <!-- Your page content here -->

    <ng-container *ngIf="!verfystatus">
        <!-- Fixed-position overlay for verification message -->
        <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.8); display: flex; justify-content: center; align-items: center; z-index: 999;">

         

            <div style="background-color: black; padding: 20px; text-align: center; color: white;">

                <img class="mb-3" src="assets/img/logo.png" alt="" style="width: 250px;margin-top: 11px;margin-left: -10px"/> <b> | ADMIN </b>

                <br/><br/>


                <h1>Verifying...</h1>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="verfystatus">
        <!-- Fixed-position overlay for verification message -->
        <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.8); display: flex; justify-content: center; align-items: center; z-index: 999;">

         

            <div style="background-color: black; padding: 20px; text-align: center; color: white;">

                <img class="mb-3" src="assets/img/logo.png" alt="" style="width: 250px;margin-top: 11px;margin-left: -10px"/> <b> | ADMIN </b>

                <br/>    <br/> 


                
                <h3>Email Login Success</h3>

                <br/>  
        <p> Your email address was successfully authenticated</p>

        <br/>  


        <p> You will now be redirected to dashboard </p>

        <br/>  

            </div>
        </div>
    </ng-container>

</body>



    



