import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forget-password-reset',
  templateUrl: './forget-password-reset.component.html',
  styleUrls: ['./forget-password-reset.component.css']
})
export class ForgetPasswordResetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
